import React, { useState } from 'react';
import client from './feathers';

const Login = () => {
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [error, setError] = useState();

  function updateField(cb) {
    return ev => {
      cb(ev.target.value);
    };
  }

  function parseQueryPath(query) {
    if (!query) return {}
    var qq = query.split('?')
    var qq2
    if (qq.length == 1) {
        qq2 = query
    } else {
        qq2 = qq[1]
    }
    var r = {}
    qq = qq2.split('&')
    for (let i in qq) {
        let q = qq[i].split('=')
        if (q.length == 2) {
            r[q[0]] = q[1]
        }
    }
    return r
  }

  function login() {
    let params = parseQueryPath(window.location.search)
    console.log(params)
    var uid = window.livechat_uid
    if ( params.uid  &&  params.uid.length>0) {
      uid = params.uid
    }
    if (!uid || uid == '') {
      return setError({message:'LiveChat not setup yet'})
    }
    if (!email || email == '' || !name || name == '') {
      return setError({message:'Please fill email and name'})
    }
    var bid = localStorage.getItem('livechat_bid')
    if (!bid) bid = '';
    var p = phone
    if (!p) p = ''
    const password = email + "|" + bid + "|" + p + "|" + name
    console.log('uid',uid,'pass',password)
    return client
      .authenticate({
        strategy: 'local',
        email:uid,
        password,
      })
      .catch(err => setError(err));
  }

  return (
    <mainLivechat className="login containerlivechat">
      <div className="row">
        <div className="col-12 col-6-tablet push-3-tablet text-center heading">
          <h1 className="font-100">Info</h1>
          <p>{error && error.message}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-12-tablet push-12-tablet col-12-desktop push-12-desktop">
          <form className="form">
            <fieldset>
              <input
                className="block"
                type="text"
                name="name"
                placeholder="name"
                onChange={updateField(setName)}
              />
            </fieldset>
            <fieldset>
              <input
                className="block"
                type="email"
                name="email"
                placeholder="email"
                onChange={updateField(setEmail)}
              />
            </fieldset>
            <fieldset>
              <input
                className="block"
                type="number"
                name="phone"
                placeholder="phone"
                onChange={updateField(setPhone)}
              />
            </fieldset>

            <button
              type="button"
              className="buttonLivechat buttonLivechat-primary block signup"
              onClick={() => login()}
            >
              Sign In
            </button>
          </form>
        </div>
      </div>
    </mainLivechat>
  );
};

export default Login;
