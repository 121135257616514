import React, { useEffect, useRef,useState } from 'react';
import moment from 'moment';
import client from './feathers';
import './App.css';

const messagesService = client.service('chat/messages');

const Chat = ({ login,onClose }) => {
  const chatRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [first, setFirst] = useState([]);

  function sendMessage(ev) {
    const input = ev.target.querySelector('[name="text"]');
    const text = input.value.trim();
    if (text == '') {
      return
    }
    messagesService
      .create({ text:text })
      .then(() => {
        //console.log('sendMessage2',userActive)
        //let msg = {chatId:userActive.chatId,user_id:1,_id:(new Date().getTime()),text:text,createdAt:new Date()}
        //scrollToBottom(msg)
        input.value = '';
      });
    ev.preventDefault();
  }
  function loadChat() {
    if (first === true) {
      return
    }
    setFirst(true)
    messagesService
      .find({ query:{client_id: login.user.id}})
      .then((result) => {
        console.log('loadChat',result)
        setMessages(result.data.reverse())
      });
  }

  function scrollToBottom(message) {
    
    if (message) {
      if (message.userId != login.user.id) {
        return
      }
      setMessages(currentMessages => currentMessages.concat(message))

      let chat = chatRef.current;
      chat.scrollTop = chat.scrollHeight - chat.clientHeight;
    }
    if (!message) {
      let chat = chatRef.current;
      chat.scrollTop = chat.scrollHeight - chat.clientHeight;
    } else {
      console.log('new message',message)
    }
  }

  useEffect(() => {
    loadChat()
    client.service('chat/messages').on('created', scrollToBottom);
    scrollToBottom(null);
    return () => {
      // Clean up listeners
      client.service('chat/messages').removeListener('created', scrollToBottom);
    };
  });

  return (
    <mainLivechat className="flexLiveChat flexLiveChat-column login">
      <header className="title-bar flexLiveChat flexLiveChat-row flexLiveChat-center"  style={login?.theme?.header.style}>
      {login &&
              <img src={login.theme.icon} style={{width:40,height:40,float:'left',marginLeft:10}}/>
            
      }
        <div className="title-wrapper block center-element">
          {login &&
            <div>
              <span style={{fontSize:'1.2em',fontWeight:'bold'}}> {login.theme.header.title}</span>  
              <span>{login.appName}</span>
            </div>
          }
          {!(window.livechat_full == true) &&
            <div style={{float:'right',fontSize:'40',top:0}} onClick={() =>  onClose()}>X</div>
          }
        </div>
      </header>

      <div className="flexLiveChat flexLiveChat-row flexLiveChat-1 clear">

        <div className="flexLiveChat flexLiveChat-column col col-12"  >
          <div className="chat flexLiveChat flexLiveChat-column flexLiveChat-1 clear" ref={chatRef}>
            {messages.map(message => (
              <div key={message.id} className="message flexLiveChat flexLiveChat-row">
                {message.fromMe === 1 &&
                  <img className="profileImage" src={login.theme.icon}></img>
                }
                {message.fromMe === 0 &&
                  <span className="profileImage" style={{background:'green',paddingTop:2}}>Y</span>
                }
                <div className="message-wrapper">
                  <p className="message-header">
                    <span className="username font-600">
                      {message.user_id === 0 && login.appName}
                      {message.user_id > 0 && 'You' }
                    </span>
                    <span className="sent-date font-300">
                      {moment(message.createdAt).fromNow()}
                    </span>
                  </p>
                  {message.type == 9 &&
                    <img src={"https://pay.waba.link/media/" + message.text}></img>
                  }
                  {message.type != 9 && 
                    <p className="message-content font-300">{message.text}</p>
                  }
                </div>
              </div>
            ))}
          </div>
          <form 
            onSubmit={sendMessage.bind(this)} 
            className="flexLiveChat flexLiveChat-row flexLiveChat-space-between"
            id="send-message"
          >
            <input type="text" name="text" className="flexLiveChat flexLiveChat-1"/>
            <button className="buttonLivechat-primary" type="submit">
              Send
            </button>
          </form>
        </div>
      </div>
    </mainLivechat>
  );
};

export default Chat;
