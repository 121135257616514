import React, { useState, useEffect } from 'react';
import Login from './Login';
import Chat from './Chat';
import client from './feathers';

const Application = () => {
  const [login, setLogin] = useState(null);
  const [show, setShow] = useState(null);

  useEffect(() => {
    // Try to authenticate with the JWT stored in localStorage
    client.authenticate().catch(() => {
      setLogin(null);
    });

    // On successfull login
    client.on('authenticated', loginResult => {
        
        loginResult.theme = loginResult.authentication.payload.theme
        console.log('authenticated',loginResult)
        setLogin(loginResult);
    });

    // On logout reset all all local state (which will then show the login screen)
    client.on('logout', () => {
      setLogin(null);
    });
    
  }, []);
  
  if (!(window.livechat_full === true) && show === null) {
    return (
      <buttonLivechat style={{zIndex:1000,position:'absolute',right:0,bottom:0}} onClick={() => setShow(true)}><img src="/cs.jpeg" className="iconLiveChat"></img></buttonLivechat>
    )
  } else if (login === undefined) {
    return (
      <mainLivechat className="container text-center">
        <h1>Loading...</h1>
      </mainLivechat>
    );
  } else if (login) {
    return <Chat login={login} onClose={()=>setShow(null)}/>;
  }

  return <Login />;
};

export default Application;
